import logo from './logo.svg';
import './App.css';

function App() {
  return (
  <div>

          <p>Hackman Handmades</p>
            <br/>
              <a mailto="contact@hackmanhandmades.com" style={{ textDecoration: 'none', color: 'darkorange' }} >contact@</a>
            <br></br>
            <br></br>
            
              <a href="https://www.facebook.com/hackmanhandmades/" style={{ textDecoration: 'none' }} 
              target="_blank" 
              rel="noopener noreferrer">
                <img src="f_logo_RGB-White_58.png" alt="fb" style={{width: '30px', height: '30px'}} />
              </a>
            
              <a href="https://www.instagram.com/hackmanhandmades/" style={{ textDecoration: 'none' }} 
              target="_blank" 
              rel="noopener noreferrer">
                <img src="insta100.png" alt="insta" style={{width: '30px', height: '30px'}} />
              </a>
  

    </div>
  );
}

export default App;
